(function () {
    if (window.location.hostname === "localhost") {
        var cookieBanner = document.getElementById('cookie-banner');
        if (cookieBanner) {
            cookieBanner.remove();
        }

        return;
    }

    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }

    gtag('js', new Date());
    gtag('config', 'G-BVMDN7Z2FF');

    // Is consent cookie set?
    if (document.cookie.split(';').some((item) => item.includes('.AspNet.Consent'))) {
        console.log("Consent cookie exists!");

        if (document.cookie.split(';').some((item) => item.includes('.AspNet.Consent=yes'))) {
            console.log("Consent Accepted!");
            gtag('consent', 'default', {
                'ad_storage': 'granted',
                'analytics_storage': 'granted',
                'personalization_storage': 'granted',
                'functionality_storage': 'granted',
                'security_storage': 'granted',
            });
        } else {
            console.log("Consent Denied!");
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'analytics_storage': 'denied',
                'personalization_storage': 'denied',
                'functionality_storage': 'denied',
                'security_storage': 'denied',
            });
        }

        // The banner is already hidden but it might be better to remove it completely
        var cookieBanner = document.getElementById('cookie-banner');
        if (cookieBanner) {
            cookieBanner.remove();
        }
    } else {
        // By default everything has to be denied
        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'personalization_storage': 'denied',
            'functionality_storage': 'denied',
            'security_storage': 'denied',
        });

        // Display the banner
        var cookieBanner = document.getElementById('cookie-banner');
        cookieBanner.classList.remove('d-none');

        // Accept button listener
        var acceptButton = document.querySelector(".accept-policy");
        acceptButton.addEventListener("click", function (event) {
            console.log("Consent Accepted!");
            document.cookie = acceptButton.dataset.cookieString;

            // Allow GA tracking after consent
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'analytics_storage': 'granted',
                'personalization_storage': 'granted',
                'functionality_storage': 'granted',
                'security_storage': 'granted'
            });
        }, false);

        // Deny button listener
        var denyButton = document.querySelector(".deny-policy");
        denyButton.addEventListener("click", function (event) {
            console.log("Consent Denied!");
            document.cookie = ".AspNet.Consent=no; expires=" + new Date(new Date().setFullYear(new Date().getFullYear() + 1)) + "; path=/";
        }, false);
    }

})();