$(function () {
	initializeAjaxPartial();
	initializeLocalization();
	initializeSelect2();
	initializeNotificationBell();

	$('#validate-solax-cloud-api-key').click(function () {
		var registrationNo = $('#RegistrationNo').val();
		var tokenId = $('#TokenId').val();

		$.post('/api/solax-log/validate-api-key', { registrationNo: registrationNo, tokenId: tokenId })
			.done(function (data) {
				if (data == true) {
					$("#save-solax-cloud-api-key").removeClass("disabled");
					$("#validate-solax-cloud-api-key-failed").addClass("d-none");

					// Make inputs readonly
					$("#TokenId").prop("readonly", true);
					$("#RegistrationNo").prop("readonly", true);

				} else {
					$("#save-solax-cloud-api-key").addClass("disabled");
					$("#validate-solax-cloud-api-key-failed").removeClass("d-none");
				}
			})
			.fail(function (xhr, status, error) {
				console.log(error);
				$("#save-solax-cloud-api-key").addClass("disabled");
				$("#validate-solax-cloud-api-key-failed").removeClass("d-none");
			});
	});
});

function initializeSelect2() {
	// Select 2
	if (jQuery && $.fn.select2) {
		$(".default-select2").select2();
		$(".multiple-select2").select2({
			closeOnSelect: false,
			placeholder: function () {
				$(this).data('placeholder');
			}
		});
	}
}

function initializeNotificationBell() {
	$('body').on('click', '#notification-bell', function () {
		$('#notification-badge').hide();

		$.ajax({
			url: '/api/notifications/all/mark-as-read',
			type: 'POST',
			success: function (data) {
				console.log(data);
			}
		});
	});

	$('body').on('click', '.a-href', function () {
		var data = $(this).data('a-href');
		window.location.href = data;
	});
}

function initializeLocalization() {
	// Localization
	if (typeof moment === "function") {
		var culture = getCultureFromCookie();
		if (culture === 'cs-CZ') {
			moment.locale('cs');
		}
		else {
			moment.locale('en');
		}
	}

	//$('.localization').click(function (event) {
	//	console.log("localization click");
	//	var selectedCulture = $(this).data('culture');
	//	$.post('/localization/SetLanguage', { culture: selectedCulture })
	//		.done(function () {
	//			console.log("done");
	//			location.reload();
	//		})
	//		.fail(function (xhr, status, error) {
	//			// error handling
	//			console.log("fail");
	//			alert(error);
	//		});
	//	event.preventDefault(); // cancel the event
	//});

	$('.localization').click(function () {
		var selectedCulture = $(this).data('culture');
		$.post('/localization/SetLanguage', { culture: selectedCulture, returnUrl: window.location.pathname }, function () {
			location.reload();
		});
	});
}

function initializeAjaxPartial() {
	$('.ajax-partial').each(function () {
		var $this = $(this);
		loadAjaxHtml($this);
	});
}

function loadAjaxHtml($this) {
	var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	var url = $this.data('ajax-url');

	// Add a few optional parameters
	var content = document.getElementById('content');
	if (content) {
		// Url contains query parameters?
		if (url.includes("?")) {
			url += "&";
		} else {
			url += "?";
		}

		// Add content width
		url += "contentWidth=" + content.clientWidth;
	}

	// Add timezone
	if (timezone) {
		// Url contains query parameters?
		if (url.includes("?")) {
			url += "&";
		} else {
			url += "?";
		}

		url += "timezone=" + timezone;
	}

	if (url) {
		$.get(url, function (data) {
			$this.html(data);
			handelTooltipPopoverActivation(); // re-activate tooltips and popovers
			hideAddOrRemoveWidgetButtons();
			hideMoveUpAndDownWidgetButtons();
		});
	}
}