// Execute gulpfile task js-site to update the translations
var localizer = {
    "Count": {
        "en-US": "Count",
        "cs-CZ": "Počet",
    },
    "State": {
        "en-US": "State",
        "cs-CZ": "Stav",
    },
    "Total": {
        "en-US": "Total",
        "cs-CZ": "Celkem",
    },
    "Inverter": {
        "en-US": "Inverter",
        "cs-CZ": "Střídač",
    },
    "Utility Grid": {
        "en-US": "Utility Grid",
        "cs-CZ": "Síť",
    },
    "Power DC1": {
        "en-US": "Power DC1",
        "cs-CZ": "Energie DC1",
    },
    "Power DC2": {
        "en-US": "Power DC2",
        "cs-CZ": "Energie DC2",
    },
    "Export": {
        "en-US": "Export",
        "cs-CZ": "Export",
    },
    "Import": {
        "en-US": "Import",
        "cs-CZ": "Import",
    },
    "Battery": {
        "en-US": "Battery",
        "cs-CZ": "Baterie",
    },
    "Difference": {
        "en-US": "Difference",
        "cs-CZ": "Rozdíl",
    },
    "Today": {
        "en-US": "Today",
        "cs-CZ": "Dneska",
    },
    "Yesterday": {
        "en-US": "Yesterday",
        "cs-CZ": "Včera",
    },
    "Profile 1": {
        "en-US": "Profile 1",
        "cs-CZ": "Profil 1",
    },
    "Profile 2": {
        "en-US": "Profile 2",
        "cs-CZ": "Profil 2",
    },
    "Today Grid": {
        "en-US": "Today Grid",
        "cs-CZ": "Dneska ze sítě",
    },
    "Yesterday Grid": {
        "en-US": "Yesterday Grid",
        "cs-CZ": "Včera ze sítě",
    },
    "Today Inverter": {
        "en-US": "Today Inverter",
        "cs-CZ": "Dneska ze střídače",
    },
    "Yesterday Inverter": {
        "en-US": "Yesterday Inverter",
        "cs-CZ": "Včera ze střídače",
    },
    "Today Household": {
        "en-US": "Today Household",
        "cs-CZ": "Dneska domácnost",
    },
    "Yesterday Household": {
        "en-US": "Yesterday Household",
        "cs-CZ": "Včera domácnost",
    },
    "Production": {
        "en-US": "Production",
        "cs-CZ": "Výroba",
    },
    "Consumption": {
        "en-US": "Consumption",
        "cs-CZ": "Spotřeba",
    },
    "Export to grid": {
        "en-US": "Export to grid",
        "cs-CZ": "Export do síťě",
    },
    "Expected": {
        "en-US": "Expected",
        "cs-CZ": "Očekávaná",
    },
    "Actual": {
        "en-US": "Actual",
        "cs-CZ": "Skutečná",
    },
    "Household": {
        "en-US": "Household",
        "cs-CZ": "Domácnost",
    },
    "Minutes": {
        "en-US": "Minutes",
        "cs-CZ": "Minut",
    },
    "Hours": {
        "en-US": "Hours",
        "cs-CZ": "Hodin",
    },
    "Days": {
        "en-US": "Days",
        "cs-CZ": "Dní",
    },
    "Date": {
        "en-US": "Date",
        "cs-CZ": "Datum",
    },
    "Power Total": {
        "en-US": "Power Total",
        "cs-CZ": "Celkový výkon",
    },
    "Power DC1": {
        "en-US": "Power DC1",
        "cs-CZ": "Výkon DC1",
    },
    "Power DC2": {
        "en-US": "Power DC2",
        "cs-CZ": "Výkon DC2",
    },
    "Soc": {
        "en-US": "Soc",
        "cs-CZ": "Stav baterie",
    },
    "AC power": {
        "en-US": "AC power",
        "cs-CZ": "AC výkon",
    },
    "Consume energy": {
        "en-US": "Consume energy",
        "cs-CZ": "Spotřeba energie",
    },
    "Total consumption": {
        "en-US": "Total Consumption",
        "cs-CZ": "Celková spotřeba",
    },
    "Battery power": {
        "en-US": "Battery power",
        "cs-CZ": "Výkon baterie",
    },
    "Production today": {
        "en-US": "Production today",
        "cs-CZ": "Výroba dnes",
    },
    "Production total": {
        "en-US": "Production total",
        "cs-CZ": "Celková výroba",
    },
    "Production today": {
        "en-US": "Production today",
        "cs-CZ": "Výroba dnes",
    },
    "Total production today": {
        "en-US": "Total production today",
        "cs-CZ": "Celková výroba dnes",
    },
    "Feedin power": {
        "en-US": "Feedin power",
        "cs-CZ": "Výkon sítě",
    },
    "Feedin energy": {
        "en-US": "Feedin energy",
        "cs-CZ": "Export celkem",
    },
    "Slope": {
        "en-US": "Slope",
        "cs-CZ": "Sklon",
    },
    "Total size": {
        "en-US": "Total size",
        "cs-CZ": "Celková velikost",
    },
    "Battery voltage": {
        "en-US": "Battery voltage",
        "cs-CZ": "Napětí baterie",
    },
    "Battery temperature": {
        "en-US": "Battery temperature",
        "cs-CZ": "Teplota baterie",
    },
    "Battery capacity": {
        "en-US": "Battery capacity",
        "cs-CZ": "Kapacita baterie",
    },

    "YieldToday": {
        "en-US": "Yield today (kWh)",
        "cs-CZ": "Výroba dneska (kWh)",
    },
    // Get the value from option and translate it based on the translations below
    "InverterTempInner": {
        "en-US": "Inverter temperature inner (°C)",
        "cs-CZ": "Teplota střídače uvnitř (°C)",
    },
    "InverterTemperature": {
        "en-US": "Inverter temperature (°C)",
        "cs-CZ": "Teplota střídače (°C)",
    },
    "YieldTotal": {
        "en-US": "Yield total (kWh)",
        "cs-CZ": "Výroba celkem (kWh)",
    },
    "PowerDc1": {
        "en-US": "Power DC1 (W)",
        "cs-CZ": "Výkon DC1 (W)",
    },
    "PowerDc2": {
        "en-US": "Power DC2 (W)",
        "cs-CZ": "Výkon DC2 (W)",
    },
    "BATPower": {
        "en-US": "BAT power (W)",
        "cs-CZ": "BAT výkon (W)",
    },
    "FeedInPower": {
        "en-US": "Feed in power (W)",
        "cs-CZ": "Vstupní výkon (W)",
    },
    "GridAPower": {
        "en-US": "Grid A power (W)",
        "cs-CZ": "Síťový výkon A (W)",
    },
    "GridBPower": {
        "en-US": "Grid B power (W)",
        "cs-CZ": "Síťový výkon B (W)",
    },
    "GridCPower": {
        "en-US": "Grid C power (W)",
        "cs-CZ": "Síťový výkon C (W)",
    },
    "GridTotalPower": {
        "en-US": "Grid total power (W)",
        "cs-CZ": "Celkový síťový výkon (W)",
    },
    "FeedInEnergy": {
        "en-US": "Feed in energy (kWh)",
        "cs-CZ": "Vstupní energie (kWh)",
    },
    "EPSAPower": {
        "en-US": "EPS A power (W)",
        "cs-CZ": "EPS výkon A (W)",
    },
    "EPSBPower": {
        "en-US": "EPS B power (W)",
        "cs-CZ": "EPS výkon B (W)",
    },
    "EPSCPower": {
        "en-US": "EPS C power (W)",
        "cs-CZ": "EPS výkon C (W)",
    },
    "Vdc1": {
        "en-US": "Vdc1 (V)",
        "cs-CZ": "DC1 napětí (V)",
    },
    "Vdc2": {
        "en-US": "Vdc2 (V)",
        "cs-CZ": "DC2 napětí (V)",
    },
    "Idc1": {
        "en-US": "Idc1 (A)",
        "cs-CZ": "DC1 proud (A)",
    },
    "Idc2": {
        "en-US": "Idc2 (A)",
        "cs-CZ": "DC2 proud (A)",
    },
    "EPSAVoltage": {
        "en-US": "EPS A voltage (V)",
        "cs-CZ": "EPS napětí A (V)",
    },
    "EPSBVoltage": {
        "en-US": "EPS B voltage (V)",
        "cs-CZ": "EPS napětí B (V)",
    },
    "EPSCVoltage": {
        "en-US": "EPS C voltage (V)",
        "cs-CZ": "EPS napětí C (V)",
    },
    "EPSACurrent": {
        "en-US": "EPS A current (A)",
        "cs-CZ": "EPS proud A (A)",
    },
    "EPSBCurrent": {
        "en-US": "EPS B current (A)",
        "cs-CZ": "EPS proud B (A)",
    },
    "EPSCCurrent": {
        "en-US": "EPS C current (A)",
        "cs-CZ": "EPS proud C (A)",
    },
    "BatteryCapacity": {
        "en-US": "Battery capacity (%)",
        "cs-CZ": "Kapacita baterie (%)",
    },
    "BatteryVoltage": {
        "en-US": "Battery voltage (V)",
        "cs-CZ": "Napětí baterie (V)",
    },
    "BatteryTemperature": {
        "en-US": "Battery temperature (°C)",
        "cs-CZ": "Teplota baterie (°C)",
    },
    "BatteryRemainEnergy": {
        "en-US": "Battery remain energy (kWh)",
        "cs-CZ": "Zbývající energie baterie (kWh)",
    },
    "GridAVoltage": {
        "en-US": "Grid A voltage (V)",
        "cs-CZ": "Napětí sítě A (V)",
    },
    "GridBVoltage": {
        "en-US": "Grid B voltage (V)",
        "cs-CZ": "Napětí sítě B (V)",
    },
    "GridCVoltage": {
        "en-US": "Grid C voltage (V)",
        "cs-CZ": "Napětí sítě C (V)",
    },
    "GridACurrent": {
        "en-US": "Grid A current (A)",
        "cs-CZ": "Proud sítě A (A)",
    },
    "GridBCurrent": {
        "en-US": "Grid B current (A)",
        "cs-CZ": "Proud sítě B (A)",
    },
    "GridCCurrent": {
        "en-US": "Grid C current (A)",
        "cs-CZ": "Proud sítě C (A)",
    },
    "FreqacA": {
        "en-US": "Freqac A (Hz)",
        "cs-CZ": "Frekvence A (Hz)",
    },
    "FreqacB": {
        "en-US": "Freqac B (Hz)",
        "cs-CZ": "Frekvence B (Hz)",
    },
    "FreqacC": {
        "en-US": "Freqac C (Hz)",
        "cs-CZ": "Frekvence C (Hz)",
    },
    "Inner": {
        "en-US": "Inner",
        "cs-CZ": "Vnitřní",
    },
    "Outer": {
        "en-US": "Outer",
        "cs-CZ": "Vnější",
    },
    "On": {
        "en-US": "On",
        "cs-CZ": "Zapnuto",
    },
    "Off": {
        "en-US": "Off",
        "cs-CZ": "Vypnuto",
    },
    "No active workflows": {
        "en-US": "No active workflows",
        "cs-CZ": "Žádné aktivní automatizace",
    },
}

var tr = function (word) {
    // Is first letter lower case?
    var isLowerCase = word[0] === word[0].toLowerCase();
    word = word.charAt(0).toUpperCase() + word.slice(1);

    var uiCulture = getUiCultureFromCookie();
    if (localizer.hasOwnProperty(word) && localizer[word].hasOwnProperty(uiCulture)) {
        if (isLowerCase) {
            return localizer[word][uiCulture].toLowerCase();
        }
        return localizer[word][uiCulture];
    } 

    return word;
}

function getCultureFromCookie() {
    var aspNetCoreCulture = getCookie('.AspNetCore.Culture');
    if (!aspNetCoreCulture) {
        aspNetCoreCulture = 'c=cs-CZ|uic=cs-CZ';
    }

    return aspNetCoreCulture.split('|')[0].split('c=')[1];
}

function getUiCultureFromCookie() {
    var aspNetCoreCulture = getCookie('.AspNetCore.Culture');
    if (!aspNetCoreCulture) {
        aspNetCoreCulture = 'c=cs-CZ|uic=cs-CZ';
    }

    return aspNetCoreCulture.split('|')[1].split('uic=')[1];
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}